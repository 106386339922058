import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

export default function BurgerMenu({open, setOpen}) {
  const location = useLocation();

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [location]);

  return (
    <div className={`burger-menu ${open ? 'burger-menu-opened' : ''}`} onClick={() => setOpen(!open)}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

BurgerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
