import React from 'react';
import PropTypes from 'prop-types';

export default function Wrapper({children, style}) {
  const styles = {
    maxWidth: 1200,
    margin: '0 auto',
    ...(style || {}),
  };

  return (
    <div style={styles}>
      {children}
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};
