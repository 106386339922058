import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Header from './Header';
import {AuthContext} from './AuthContext';
import Footer from './Footer';

const styles = (theme) => ({
  main: theme.typography.body1,
});

const Layout = ({children, classes}) => {
  const {fetchUserAuth} = useContext(AuthContext);

  useEffect(() => {
    fetchUserAuth();
  }, []);

  return (
    <div className={classes.main}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
