import React from 'react';
import PropTypes from 'prop-types';


const GridComponent = ({columnNum, children, style, centered}) => {
  let _style = style || {};
  if (centered) {
    _style = {
      ..._style,
      margin: '0 auto',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }

  return (
    <div
      className={`grid grid-col-${columnNum}`}
      style={_style}
    >
      {children}
    </div>
  );
};

export const Grid = (GridComponent);

GridComponent.propTypes = {
  columnNum: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  centered: PropTypes.bool,
};
