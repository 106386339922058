import React, {Fragment, useContext} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import useDimensions from 'react-use-dimensions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// import {PeopleContext} from '../PeopleContext';

import LinkButton from './LinkButton';
import Wrapper from './../Wrapper';
import BurgerMenu from './BurgerMenu';
import {AuthContext} from '../AuthContext';

const styles = {
  flex: {
    flex: 1,
  },
  bg: {
    backgroundColor: '#282c40',
  },
};

const Header = ({classes}) => {
  // const {person} = useContext(PeopleContext);
  const [ref, {width}] = useDimensions();

  const [open, setOpen] = React.useState(false);

  const {auth} = useContext(AuthContext);

  const isRow = width > 800;

  return (
    <Fragment>
      <AppBar position='static' style={{backgroundColor: '#282c40', position: 'relative'}} ref={ref}>
        <Toolbar variant='dense'>
          <Wrapper style={
            {
              display: 'flex',
              paddingTop: 10,
              flexDirection: isRow === true ? 'row' : 'column',
              alignItems: isRow === true ? 'flex-end' : 'center',
              justifyContent: 'space-between',
              flexWrap: 'no-wrap',
              gap: 10,
            }}>
            {isRow === false && <BurgerMenu open={open} setOpen={setOpen} />}
            <Link to="/" >
              <img src={'/images/auto-registracija-logo.png'} alt="logo" width={isRow === true ? 150 : 80} />
            </Link>
            {isRow === true ? <Typography className={classes.flex}>
              <LinkButton path='/' label='Kalkulator' small={!isRow} />
              <LinkButton path='/tehnicki-pregledi' label='Tehnički pregledi' small={!isRow} />
              <LinkButton path='/implementacija' label='Saradnja' small={!isRow} />
            </Typography> : null}
            {isRow === true && !auth ? <div className='top-links'>
              <Link to='/prijava' >Prijava</Link>
              <Link to='/registracija' >Registracija</Link>
            </div> : null}
            {isRow === true && auth ? <div className='top-links'>
              <Link to='/profil' >Profil ({auth.username})</Link>
              <Link to='/odjava' >Odjava</Link>
            </div> : null}
          </Wrapper>
        </Toolbar>
      </AppBar>
      {open && <div className='burger-menu-overlay' onClick={() => setOpen(false)} />}
      <div className={`burger-menu-sidebar ${open ? 'burger-menu-sidebar-opened' : ''}`}>
        <Link to='/' >Kalkulator</Link>
        <Link to='/tehnicki-pregledi' >Tehnički pregledi</Link>
        <Link to='/implementacija' >Saradnja</Link>
        {!auth && <Fragment>
          <Link to='/prijava' >Prijava</Link>
          <Link to='/registracija' >Registracija</Link>
        </Fragment>}
        {auth && <Fragment>
          <Link to='/profil' >Profil ({auth.username})</Link>
          <Link to='/odjava' >Odjava</Link>
        </Fragment>}
      </div>
    </Fragment>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
