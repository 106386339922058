import request from './../dataFetchers/request';

export const api = () => {
  const _url = process.env.API_URL;

  const _get = async (_path) => {
    return await request.get(`${_url}${_path}`);
  };

  const _post = async (_path, data) => {
    return await request.post(`${_url}${_path}`, {data});
  };

  const _put = async (_path, data) => {
    return await request.put(`${_url}${_path}`, {data});
  };

  const _delete = async (_path) => {
    return await request.delete(`${_url}${_path}`);
  };

  return {
    get: _get,
    post: _post,
    put: _put,
    delete: _delete,
  };
};
