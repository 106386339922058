import React from 'react';
import PropTypes from 'prop-types';

const AppDivider = ({text}) => (
  <div className="divider">
    {text && <span>{text}</span>}
  </div>
);

export default AppDivider;

AppDivider.propTypes = {
  text: PropTypes.string, // Tekst koji se prikazuje u okviru dividera
};
