import React from 'react';
import Wrapper from '../Wrapper';
import {Grid} from '../Grid';
import {Divider} from '@material-ui/core';
import AppDivider from '../AppDivider';

const Footer = () => {
  return (
    <footer>
      <Wrapper>
        <div className="footer">
          <AppDivider text="Grupacija" />
          <Grid columnNum={5} centered>
            <a href="https://prodajemauto.rs" target="_blank" rel="noreferrer" alt="Prodajem Auto Oglasi" className="footer-partner">
              <img src="/images/prodajem-auto.png" alt="Prodajem Auto Oglasi" width={150} />
            </a>
            <a href="https://automobili.rs" target="_blank" rel="noreferrer" alt="Auto Vesti | Automobili" className="footer-partner">
              <img src="/images/automobili.png" alt="Auto Vesti | Automobili" width={150} />
            </a>
            <a href="https://auto-registracija.rs" target="_blank" rel="noreferrer" alt="Auto Registracija" className="footer-partner">
              <img src="/images/auto-registracija-partner.png" alt="Auto Registracija" width={150} />
            </a>
            <a href="https://hourhappy.net" target="_blank" rel="noreferrer" alt="Happy Hour" className="footer-partner">
              <img src="/images/happy-hour.png" alt="Happy Hour" width={150} />
            </a>
          </Grid>
          <Divider />
          <p className="text-center">
                &copy; 2024 - {new Date().getFullYear()} Auto Registracija. All Rights Reserved. | Design by <a href="https://prodajemauto.rs" target="_blank" rel="noreferrer">Prodajem Auto Oglasi</a>
          </p>
        </div>
      </Wrapper>
    </footer>
  );
};

export default Footer;
