import React from 'react';
import PropTypes from 'prop-types';
import {useLocation, Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = {
  Link: {
    textDecoration: 'none',
  },

  ButtonSelected: {
    color: 'white',
  },

  ButtonNotSelected: {
    color: '#8d8f97',
  },

  FontSmall: {
    fontSize: 13,
    padding: '2px 5px',
  },
  FontRegular: {
    fontSize: 16,
  },
};

const LinkButton = ({path, label, small}) => {
  const location = useLocation(); // Koristimo React Hook za dobijanje trenutne lokacije


  let style = small ? styles.FontSmall : styles.FontRegular;
  if (location.pathname.startsWith(path)) {
    style = {...style, ...styles.ButtonSelected};
  } else {
    style = {...style, ...styles.ButtonNotSelected};
  }

  return (
    <Link style={styles.Link} to={path}>
      <Button
        style={style}
      >
        {label}
      </Button>
    </Link>
  );
};

LinkButton.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

export default LinkButton;
