import React, {Fragment, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {hydrate, render} from 'react-dom';
import {hot} from 'react-hot-loader/root';
import Routes from './routes.browser';
import {Helmet} from 'react-helmet';
import './styles/main.scss';

const App = () => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:image" content="https://auto-registracija.rs/images/websajt.jpg" />
        <meta property="og:image:secure_url" content="https://auto-registracija.rs/images/websajt.jpg" />
        <meta property="og:url" content="https://auto-registracija.rs" />
        <meta property="og:site_name" content="Auto Registracija Vozila" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router>
        <Routes />
      </Router>
    </Fragment>
  );
};

/* Check if the Server Pre-fetch some of the App data and we need to `hydrate` the App with it */
const renderMethod = global.__INITIAL_FETCHED_SERVER_DATA__ ? hydrate : render;
renderMethod(<App />, document.getElementById('app'));

export default module.hot ? hot(App) : App;
