import {api} from '../utils/api';

export const fetchAuth = async () => {
  /* Fetch general Person data like `name`, `height`, `mass` */
  const {data: auth} = await api().get(`auth`);

  return auth;
};

export const passportAuth = async (username, password) => {
  /* Post username and password to get the auth token */
  const {data: auth} = await api().post(`auth`, {username, password});
  return auth;
};

export const unsigninAuth = async () => {
  /* Unsign in the user */
  const {data: auth} = await api().post(`unsignin`);
  return auth;
};
