import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
// import {Switch} from 'react-router';
import {ThemeProvider} from '@material-ui/styles';
// import waitingComponent from './utils/waitingComponent';
import AppTemplate from './AppTemplate';
import theme from './utils/theme';
import {AuthContextProvider} from './AppTemplate/AuthContext';

const Home = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Signin = lazy(() => import('./pages/Signin'));
const TehnickiPregledi = lazy(() => import('./pages/TehnickiPregledi'));
const Unsignin = lazy(() => import('./pages/Unsingin'));

const MyRoutes = () => (
  <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <AppTemplate>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path='/prijava' component={Signin} />
            <Route exact path='/odjava' component={Unsignin} />
            <Route path='/tehnicki-pregledi' component={TehnickiPregledi} />
            {/* <Redirect exact from='/' to='/home' /> */}
            {/* <Route path='/people/:personId' element={waitingComponent(People)} /> */}
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </AppTemplate>
    </AuthContextProvider>
  </ThemeProvider>
);

export default MyRoutes;
