import React, {createContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import useImmutableState from '../utils/useImmutableState';

import {getInitialFetchedServerData} from '../dataFetchers/initialFetchedServerData';
import {fetchAuth, passportAuth, unsigninAuth} from '../dataFetchers/fetchAuth';

const AuthContext = createContext();

const AuthContextProvider = ({children}) => {
  const {auth: initialAuth = null} = getInitialFetchedServerData();
  const [auth, setAuth] = useImmutableState(initialAuth);
  const [isLoading, setLoading] = useImmutableState(false);
  const [errors, setErrors] = useImmutableState([]);

  const fetchUserAuth = async () => {
    setLoading(true);

    try {
      const _auth = await fetchAuth();
      if (_auth?.data?.user?.id) {
        setAuth(_auth?.data.user);
      } else {
        setAuth(null);
      }
    } catch (error) {
      if ((error.response || {}).status === 404) {
        setErrors([new ReferenceError(`Auth was not found`)]);
        return;
      }

      setErrors([error]);
    } finally {
      setLoading(false);
    }
  };


  const postPassportAuth = async (username, password) => {
    setLoading(true);
    let status = false;
    try {
      const _auth = await passportAuth(username, password);
      if (_auth?.data?.user?.id) {
        status = true;
        setAuth(_auth.data.user);
      } else {
        status = false;
        setAuth(null);
      }
    } catch (error) {
      status = false;
      if ((error.response || {}).status !== 200) {
        setErrors([new ReferenceError(`Auth was not found`)]);
        setAuth(null);
        return;
      }

      setErrors([error]);
    } finally {
      setLoading(false);
    }

    return status;
  };

  const postUnsigninAuth = async () => {
    const response = await unsigninAuth();
    if (response.status === 200) {
      setAuth(null);
      return true;
    }
    return false;
  };

  /**
   * Our `providerValue` relies on props that are "values" (`person`, `isLoading`, `error`)
   * and props that are "functions" (`fetchPerson`).
   * When the provider re-renders - it'll have the same set of "values" props but new set of "functions" props.
   * This will make all of the provider's children to unnecessary re-render.
   * In order to avoid this re-rendering we'll change our `providerValue` only when the "values" props change.
   * https://kentcdodds.com/blog/always-use-memo-your-context-value
   */
  const providerValue = useMemo(() => ({
    auth,
    fetchUserAuth,
    postPassportAuth,
    postUnsigninAuth,
    isLoading,
    errors,
  }), [
    auth,
    isLoading,
    errors,
  ]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export {
  AuthContext,
  AuthContextProvider,
};
